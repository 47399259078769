







































































import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
import { Notify } from "quasar";

@Component
export default class ResultadoComponent extends Vue {
  @Prop({ required: true })
  private errorType!: string;
  @Prop({ required: true })
  private nombre_apoderado!: string;
  @Prop({ required: true })
  private nombre_alumno!: string;

  private sendMail(correo: string) {
    window.open("mailto:" + correo);
  }
}
